.table-class {
    font-family: Inconsolata;
    color: white;
    

}

.table-header{

    
    margin-top: 10px;

}

.title-table {
    margin-top: 59px;
    margin-bottom: 59px;
    font-size: 30px;
}



.td {
    padding: 10px;
    
}

table {
    width: 100%;
    border-collapse: collapse;
    
  }


thead th {
    text-align: center;
    border-bottom: 2px solid rgb(255, 255, 255);
    padding-bottom: 30px;
    padding-left: 5px ;
    
    background: rgba(0, 0, 0, 0);
    
  }

  .ranking-body {
    background: rgba(0, 0, 0, 0.288);
    width: 90%;
    margin: auto;
    padding: 30px;
    border-radius: 20px;
    margin-bottom: 10%;
   
  }

  .ranking-body-mobile {
    background: rgba(0, 0, 0, 0.288);
    width: 100%;
    height: 100%;
    display: flex;
    overflow-x: auto;

  }


  tbody td {
    padding: 0.7em;
    border-bottom: 1px solid #ccc;
    font-weight: 700;
    background: rgba(0, 0, 0, 0);
  }

  .supply {
    color: rgb(167, 159, 159);
    margin: 0;
    font-size: 15px;
  }

  .td-priceChange24h-false {
    color: #ea3943;
    background-color: #ea394218 ;
    width: fit-content;
    padding: 5px;
    border-radius: 5px;
    
  }

  .td-priceChange24h-true {
    color: #23cf8d;
    background-color: #16c78317 ;
    width: fit-content;
    padding: 5px;
    border-radius: 5px;
    
  }


  .ranking-header {
    text-align: left;
    font-family: Inconsolata;
    padding-top: 30px;
    padding-bottom: 30px;
    padding: 5%;
    color: white;
  }


  .rankingcontainer {
    background: #1B1C31;  /* fallback for old browsers */
    margin-top: 50px;
  }