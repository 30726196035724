.admin-dashboard-container {
    color: white;
}

.logout-button {
    border: none;
    color: #6287F8;
    background-color: #6288f831;
    font-family: Inconsolata;
}

.upcoming-container-admin {
    background: #1B1C31;  /* fallback for old browsers */

    color: white;
    margin-bottom: 100px;
}

.admin-section-title {
    font-family: Inconsolata;
    font-size: 30px;
    text-align: left!important;
}


.admin-filter {
    display: flex;
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
    width: 90%;
    flex-wrap: wrap;
    margin: auto;
    border-radius: 80px;
    background-color: rgba(0, 0, 0, 0.527);
    justify-content: space-between;
    
}

.input {
    
    height: 47px;
    border-radius: 80px;
    padding: 10px;
    width: 100%;
    background-color: rgba(30, 98, 223, 0);
    color: white;
    border: solid 1px #6287F8;
}