.featured-twitter-count-twittercards {
    text-decoration: none;
    color: white;
    font-weight: 800;
    font-size: 11px;
    min-width: 50px;
    
    
   
    
    padding:6px;
    border-radius: 6px;
    margin-right: 2px;
}

.featured-twitter-followers-twittercards {
    display: flex;
    background-color: rgba(7, 12, 20, 0.678);
    padding: 2%;
    border-radius: 6px;
}

.twitterincrease-twittercards {
    color:rgba(28, 253, 140, 0.932);
    font-size: 10px;
    
}