.profilecontainer {
    margin-top: 100px;
    color: white;
    margin-bottom: 100px;
    
}

.fullwidth-link-container {
    width: 700px;
    margin: auto;
}
