.Allyouneedtoknow-container {
    
    align-items: center;
    justify-content: center;
    margin: auto;
    display: inline-block;
    width: 100%;
    height:fit-content;
    margin-bottom: 20px;
    color: white;
    
}

.big-title {
    font-family: Inconsolata;
    font-size: 45px;
    text-align: center;
    font-weight: 700;
    line-height: normal;
    padding: 5%;

}

.subtitle {
    font-family: Inconsolata;
    font-size: 25px;
    text-align: center;
    font-weight: 700;
}

.title-container {
    justify-content: center;
    
}

.title-container {
    padding-left: 30px;
    padding-right: 30px;
}

.nft-title {
    color: #ffffff;
    background: #de6161;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #21e6c1, #f7559a);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #071e3d,#4bf9ee); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: 0.5%;
    border-radius: 5px;

}

.nft-titl{
    color: #ffffff;
    background-color: #19d79bc9;
    padding: 0.5%;
    border-radius: 5px;

}

.button-container-header {
    width: 40%;
    margin: auto;
    justify-content: space-evenly;
    flex-wrap: wrap;
    display: flex;
}

.submitcollection-button {
    margin-top: 10px;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    font-weight: 800;
    font-size: 14px;
    border: #F7559A 1px solid;
    box-shadow: #f7559a 0px 0px 3px 1px;
    padding: 10px;
    border-radius: 6px;
    margin-left: 2px;
    white-space: nowrap;
}

.upcoming-button {
    margin-top: 10px;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    font-weight: 800;
    font-size: 14px;
    border: #08d6c9 1px solid;
    box-shadow: #08d6c9 0px 0px 3px 1px;
    padding: 10px;
    border-radius: 6px;
    margin-left: 2px;
    white-space: nowrap;
}

.numberof-collections {
    margin-top: 10px;
    font-family: Inconsolata;
    text-align: center;
}