.featured-card {
    max-width: 30%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.288);
    border-radius: 8px;
    min-width: 330px;
    margin-bottom: 20px;
    
   
}

.featured-first-container {
    display: flex;
}

.featured-name {
    font-size: 18px;
    font-weight: 800;

}

.featured-descrption {
    font-size: 12px;
    font-weight: 500;
    color: rgba(146,146,158);
    
    
}
.sparklines-container {
    display: flex;
}

.icon-container-modal {
    display: flex;
    position: absolute;
    right: 10px;
}

.link-items {
    
}

.modal-title-description-container {
    
    width: 80%;
}

.modal-header-container {
    display: flex;
    justify-content: space-between;
    
}

.sparking {
    width: 20%;
    color: rgb(88, 64, 224);
    
}


.sparking-twenty {
    margin-top: -8px;
}

.featured-projectimage {
    width: 120px;
    border-radius: 10px;
    margin: 12px;
}

.projectimage-modal {
    max-height: 180px;
    border-radius: 10px;
    
}

.projectimage-modal-mobile {
    width: 100%;
    border-radius: 10px;
    
}

.modal-price {
    margin: auto;
}

.featured-date {
    font-family: Inconsolata;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline yellow;

}

.featured-card-links {
    color: rgba(146,146,158);
    
}

.modal-dropscore-social {
    background-color: rgba(0, 0, 0, 0.288);
    border-radius: 10px;
    margin-top: 5px;
}


.modal-dropscore-social-mobile {
    width: fit-content;

    background-color: rgba(0, 0, 0, 0.288);
    border-radius: 10px;
    margin-top: 5px;
}

.card-links-featuredcards {
    text-decoration: none;
    color: #ffffffda;
    padding-left: 5px;
    padding-right: 10px;
    padding-bottom: 0px;
    font-weight: 700;
    border-radius: 6px;
} 

.featured-footer-container {
    display: flex;
    margin-top: 0;
}

.featured-followers-count-container {
    
}

.td-priceChange24h-true-featuredcard {
    color: rgb(26, 241, 241);
    font-family: Inconsolata;
    font-size: 13px;
    background-color: rgba(26, 196, 196, 0.144);
    padding: 4px;
    border-radius: 3px;
    
}

.td-priceChange24h-false-featuredcard {
    color: tomato;
    font-family: Inconsolata;
    font-size: 13px;
    background-color: rgba(255, 99, 71, 0.137);
    padding: 4px;
    border-radius: 3px;
}


.featured-projectimage {
   

}

.image-container-featuredcard {
    
}



.followers-stats-featured-card {
    display: flex;
    justify-content: space-evenly;
   
}

.followers-stats-featured-card-modal {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
    
}

.dropscore-featuredcard {
    font-family: Inconsolata;
    font-size: 15px;
    height: 27px;
}

.modal-stats-container {
    
    display: flex;
    flex-wrap: wrap;
    
    
}

.solana {
    width: 25px;
    height: 25px;
    
    
}

.ethereum {
    width: 15px;
}

.solana-cart-container {
    display: flex;
    width: fit-content;
    
    height: fit-content;
    float: right;
}

.dropscore-featuredcard-score {
   
    box-shadow: #08d6c9 0px 0px 3px 2px;
    color: white;
    width: fit-content;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    

}

.dropscore-featuredcard-score-yellow {
box-shadow: #bae61c 0px 0px 3px 2px;
    color: white;
    width: fit-content;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
}

.dropscore-featuredcard-score-red {
    box-shadow: #e61c1c 0px 0px 3px 2px;
    color: white;
    width: fit-content;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
}



.twentyhours-featuredcard {
    font-size: 9px;
    color: rgba(255, 255, 255, 0.548);
    font-weight: 500;
    margin-bottom: 0;

    

}

.featured-twitter-count {
    text-decoration: none;
    color: white;
    font-weight: 800;
    font-size: 11px;
    background-color: rgba(52,92,159);
    padding:6px;
    border-radius: 6px;
    margin-right: 2px;
    
}

.featured-discord-count {
    text-decoration: none;
    color: white;
    font-weight: 800;
    font-size: 11px;
    background-color: rgba(106,69,152);
    padding:6px;
    border-radius: 6px;
    margin-left: 2px;
    white-space: nowrap;
}


.featured-discord-count-ty {
    text-decoration: none;
    color: white;
    font-weight: 800;
    font-size: 11px;
    border: #F7559A 1px solid;
    box-shadow: #F7559A 0px 0px 3px 1px;
    padding:6px;
    border-radius: 6px;
    margin-left: 2px;
    white-space: nowrap;
    width: fit-content;
}

.featured-followers-count-container {
  margin-bottom: 10px;
}

.featured-date-container {
    margin-top: 8px;
    margin-left: 8px;
    width: 100%;
}

.featured-blockchain-container {
    display: flex;
    margin-top: 18px;
    border: solid white;
    width: fit-content;
    
}

.blockchain-text {
    font-family: Inconsolata;
    font-weight: 600;
    font-size: 13px;
    background-color: #273078;
    color: white;
    width: fit-content;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
      
    margin: auto;   
    margin-top: 10px!important; 
}

.blockchain-text-modal {
    font-family: Inconsolata;
    font-weight: 600;
    font-size: 20px;
    background-color: #273078;
    color: white;
    width: fit-content;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    margin-top: 10px; 
    margin-right: 5px;
    white-space: nowrap;
}

.ethereum-modal {
    width: 20px;
}

.chart-chart-js {
    width: 100%;
    font-family: Inconsolata;
}

.dropscore-blockchain-modal {
    display: flex;
    flex-wrap: wrap;
}

.featured-sponsored-event {
    font-size: 10px;
    margin: 0;
    font-weight: 400;
    color: rgb(233, 201, 21);
    font-style: italic;
}

.featured-icons {
    
}

.featured-card-main {
    max-width: 30%;
    min-width: 330px;
    margin-bottom: 20px;
    cursor: pointer;
    
    
}


.blockchain-text-modal-upvote {
    font-family: Inconsolata;
    font-weight: 600;
    font-size: 20px;
    background-color: #F7559A;
    color: white;
    width: fit-content;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    margin-top: 10px; 
    margin-right: 5px;
    white-space: nowrap;
    cursor: pointer;
}

.blockchain-text-modal-upvote-featured {
    font-family: Inconsolata;
    font-weight: 800;
    font-size: 11px;
    background-color: #F7559A;
    color: white;
    width: fit-content;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    
    margin-right: 5px;
    white-space: nowrap;
    cursor: pointer;
}

.blockchain-text-modal-upvote-ty {
    font-family: Inconsolata;
    font-weight: 600;
    font-size: 20px;
    background-color: #b32d657a;
    color: white;
    width: fit-content;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    margin-top: 10px; 
    margin-right: 5px;
    white-space: nowrap;
    cursor: pointer;
}

.blockchain-text-modal-upvote-ty-featured {
    font-family: Inconsolata;
    font-weight: 800;
    font-size: 11px;
    background-color: #b32d657a;
    color: white;
    width: fit-content;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    margin-top: 10px; 
    margin-right: 5px;
    white-space: nowrap;
    cursor: pointer;
}

.linkpage-a {
    text-decoration: none;
    color: white;
}