.trending-title {
    font-family: Inconsolata;
}

.trending-container {
    padding: 10px;
    padding-top: 10px;
}

.card-container {
    
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}



.project-title {
    font-size: 20px;
}

.projectimage {
    
    width: 80px;
    border-radius: 10px;
}

.trending-subtitle {
    font-family: Inconsolata;
}

.data-container {
    display: flex;
}

.image-container {
    width: 30%;
    margin-right: 10px;
}

.safetyscore {
    font-size: 15px;
}


.link-container {
    display: flex;
}



.link-items {
    margin-right: 20px;
    
}

.next-drop {
    padding: 4%;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 8%);
    border-radius: 7px;
    font-family: Inconsolata;
    font-weight: light;
    margin: auto;
    
    width: 80%;
    height: fit-content;
   
    
    
}


.next-drop-title {
    font-family: Inconsolata;
    font-size: 25px;
    margin-left: 10px;
    
}

.next-drop-image-container {
   margin-right: 20px;
   max-width: 200px;
   min-width: 50px;
   align-items: center;

   
}

.next-drop-image {
   border-radius: 10px;
   width: 100%;
   

}

.next-drop-info-container {
   margin-left: 20px;
   
}

.dropscore-card-home-container {
    justify-content: center;
}



.next-drop-header-homepage {
    width: 90%;
    margin: auto
}

.check-all-upcoming {
    color: #ffffff;
    background: #de6161;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2657eb, #E439CA);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2657eb,#E439CA); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    padding: 0.5%;
    border-radius: 5px;
    text-decoration: none;
}