.blog-container {
    margin-top: 100px;
    color: white;
    padding: 30px;
    min-height: 70vh;
   
}

.map-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 40px;
}