.description-cards {
    
    color: rgb(245, 244, 244);
    font-size: large;
    line-height:150%
}

.dropscore {
    font-family: Inconsolata;
    color: #1bd88f;
    background-color: #06ec9425;
    font-weight: 600;
    font-size: 20px;
    width: fit-content;
    height: fit-content;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    margin-top: 10px; 
    margin-right: 5px;
    white-space: nowrap;
}

.dropscore-yellow {
    font-family: Inconsolata;
    color: #d8cb1b;
    background-color: #d8cb1b27;
    font-weight: 600;
    font-size: 20px;
    width: fit-content;
    height: fit-content;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    margin-top: 10px; 
    margin-right: 5px;
    white-space: nowrap;
}


.dropscore-red {
    font-family: Inconsolata;
    color: #e41548a2;
    background-color: #e70c431f;
    font-weight: 600;
    font-size: 20px;
    width: fit-content;
    height: fit-content;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    margin-top: 10px; 
    margin-right: 5px;
    white-space: nowrap;
}



.card {
    padding-top: 2%;
    padding-bottom: 2%;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 8%);
    border-radius: 7px;
    font-family: Inconsolata;
    font-weight: light;
    cursor: pointer;
    display: block;
    background-color: rgba(0, 0, 0, 0.288)!important;
    
    
   
}

.dropscoresvg {
    width: 10%;
    color: white;
}



.titleimage-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    
    
}


.image-container-admin {
    
}


.card-link {
    text-decoration: none;
 /* fallback for old browsers */

    color: #ffffffd5;
    padding-left: 10px;
    padding-right: 10px;
    
    
    font-weight: 700;
    
    border-radius: 6px;
    background: #de6161;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2657eba6, #e439ca96);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2657eba6,#e439ca96);
}


.card-links {
    text-decoration: none;
 

    color: #ffffff;
    
    padding-bottom: 0px;
    
    
    border-radius: 6px;
    
    
    

    

}



.release-date {
    background-color: #0000004b;
    width: fit-content;
    font-size: 15px;
    color: #ffffffd0;


}

.social-links-cards {
    display: flex;
    align-items: center;
    
    
}

.twitter-discord {
    margin-left: 40px;
}

.card-title-score-container {
    
}




.mint-price {
    color:rgba(255, 255, 255, 0.952);
    background-color: rgba(0, 0, 0, 0.452);
    width: fit-content;
    font-size: 15px;
    
    
}

.chart {
    width: 40%;
    margin: auto;
    
    
}

.Twitter-stats {
    
    border-radius: 8px;
    width: 90%;
    padding: 10px;
    font-weight: 700;
    margin: auto;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 5px;

    
}

.Twitter-stats-modal {
    background-color: rgba(0, 0, 0, 0.288);
    border-radius: 8px;
    width: 325px;
    padding: 10px;
    margin: 10px;
    font-weight: 700;
    
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 5px;
    
}


.Twitter-stats-modal-mobile {
    background-color: rgba(0, 0, 0, 0.288);
    border-radius: 8px;
    width: 100%;
    padding: 10px;
    
    font-weight: 700;
    
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 5px;
}

.Twitter-stats-modal-chartjs {
    background-color: rgba(0, 0, 0, 0.288);
    border-radius: 8px;
    width: 325px;
    padding: 10px;
    margin: 10px;
    font-weight: 700;
    
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 5px;

}

.Twitter-stats-modal-chartjs-fullwidth {
    background-color: rgba(0, 0, 0, 0.288);
    border-radius: 8px;
    width: 100%;
    padding: 10px;
    
    
    font-weight: 700;
    
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 5px;
}


.Twitter-stats-modal-chartjs-fullwidth-mobile {
    background-color: rgba(0, 0, 0, 0.288);
    border-radius: 8px;
    width: 100%;
    padding: 10px;
    
    
    font-weight: 700;
    
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 5px;
}


.social-analytics {
    
    
   
    
}

.twitter-title {
    color: #ffffff;
    background: #de6161;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2657eb, #E439CA);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2657eb,#E439CA); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    padding: 0.5%;
    border-radius: 5px;
    width: fit-content;
}

.caret-container {
    display: flex;
}

.arrow-up {
   
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    
    border-bottom: 5px solid rgba(46, 201, 32, 0.911);
    
  }

  .projectimage {
    box-shadow: 0px 0px 200px  #2657eb;
    width: 100%;
    
  }

  

  .twentyhours {
      font-size: 10px;
      color: rgba(255, 255, 255, 0.548);
      font-weight: 500;

  }

  .changedata {
      min-width: fit-content;
      
      margin: auto;
      
    
  }

  .link-container {
      justify-content: center;
      
  }

  .link-container-modal {
    justify-content: center;
    display: flex;
    background-color: rgba(0, 0, 0, 0.288);
    border-radius: 8px;
    width: fit-content;
    padding: 10px;
    margin: auto;
  }

  .mint-date-tags {
      display: flex;
      justify-content: space-evenly;
  }

  .social-score {
      display: flex;
      

  }

  .modal-90w .modal-content {
      background-color: #1B1C31;
      color: white;
      padding: 7px;
      border-bottom: none;
  }

  .modal-90w-mobile .modal-content {
    background-color: #1B1C31;
    color: white;
    padding: 20px;
    border-bottom: none;
}

 

  .modal-90w .modal-content {
    -webkit-border: 0px !important;
    -moz-border: 0px !important;
    border: 0px !important;
   
  }


  .modal-90w .modal-header {
    -webkit-border: 0px !important;
    -moz-border: 0px !important;
    border: 0px !important;
   
  }

  .btn-close {
      color: white;
      border: 1px solid white;
  }

  .price-date-card {
      margin-bottom: 12px;
      margin-top: 12px;
  }

  .modalbutton {
    background: #de6161;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2657eb, #E439CA);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2657eb,#E439CA);
    border: none;
    padding: 10px;
    color: white;
  }

  .modal-header {
      display: inline-block;
      
  }

  .modal-image-continer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      width: 100%;
      height: 100%;
  }

  .modal-image-continer-mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    
  }


.modal-image {
    height: 200px;
    width: 200px;
}

.modal-image-mobile {
    width: 30%;
}

.social-score-modal {
    display: flex;
    align-items: center;
    
    border-radius: 8px;
    padding: 10px;
    width: fit-content;
    margin-top: 10px;
   
    
}

.price-modal {
    display: flex;
    align-items:flex-start;
    
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    font-weight: 800;
}

.modal-description {
   
    color:rgb(255, 255, 255);
    padding: 10px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    
}

.modal-social-score {
    font-weight: 800;
    font-size: 20px;
    margin-right: 10px;
    max-width: 60%;
}

.modal-body .backdropClassName {
    border-bottom: none;
}

.conatiner-card-all {
    margin: auto;
    width: 400px;
    margin-top: 20px;
}

.modal-title-props {
    max-width: 75%;
    margin-top: -15px;
    font-weight: 700;
}

.modal-dropscore-social {
    
    width: 200px;
    
   

}

.modal-socialscore-price {
    display: flex;
    justify-content: space-evenly;
    font-weight: 800;
    font-size: 20px;
    margin-right: 10px;
    flex-wrap: wrap;
}

.close-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 45px;
    height: 45px;
    line-height: 36px;
    text-align: center;
    border: 0;
    font-weight: bold;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    
}

.viewmoremetrics {
    color: rgba(255, 255, 255, 0.493);
}

.releasedate-modal {
    font-style: italic;
    font-family: Inconsolata;
    text-decoration: underline yellow;
}


.delete-button {
    color: #e41548a2;
    background-color: #e70c431f;
    border: none;
}


.approve-button {
    color: #ffffff;
    font-family: Inconsolata;
    font-weight: 500;
    padding: 8px;
    background-color: #2f3035e1;
    border-radius: 8px;
    margin: 2px;
    border: none;
}

.feature-button {
    color: #eff318f1;
    background-color: #eff3181e;
    border: none;
}


.edit-button {
    color: #184bf3f1;
    background-color: #184bf32f;
    border: none;
}

.isPending {
    font-size: 15px;
    color: yellow;
    
}


.edit-box {
    padding: 10px;
    transition: all 0.5s ease-in-out;
    color: white;
    border-radius: 8px;
    font-family: Inconsolata;
    width: 90%;
    max-width: 90%;
    height: fit-content;
    margin: auto;
    z-index: 10;
    position: -webkit-sticky;
    position: fixed;
    top: 20%;
    left: 5%;
    right: 5%;
    
    
    
    background-color: rgba(0, 0, 0, 0.884);
    border: solid white;
}

.close-button-edit-box {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 45px;
    height: 45px;
    line-height: 36px;
    text-align: center;
    border: 0;
    font-weight: bold;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    background-color: rgba(255, 255, 0, 0);
    border: solid white;
    border-radius: 8px;
}

.fetch-button-edit-box {
    margin-top: 20px;
    background-color: rgba(255, 255, 255, 0);
    border: solid white ;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    
}

.edit-box-change {
    font-size: 13px;
    color: rgb(142, 142, 235);
}


.edit-box-container input, textarea{
    display: block;
    width: 100%;
    max-height: fit-content;
    margin: 0%;
    border:0;
    
    margin-bottom: 0px;
    height: fit-content;
    color: white;

    font-size: 15px;
}


.edit-box-input-form {
    background-color: rgba(0, 0, 0, 0);
    height: 10px;

}

.edit-box-label-container  {
    
    padding: 3px;
    display: flex;
}

.edit-box-label-title {
    font-size: 13px;
    color: rgb(142, 142, 235);
}