.faq-container {
    font-family: Inconsolata;
    color: white;
    padding: 4%
}

.faq-title-container {
    margin-bottom: 90px;
}

.title {
    font-size: 40px;
    color: #19D79A;
    background-color: #19d79b48;
    width: fit-content;
    padding: 5px;

}

.subtitlefaq {
    font-size: 20px;
}

.faq-content-container {
    padding: 20px;
}

.faq-content-title {
    color: #6287F8;
    background-color: #6288f817;
    width: fit-content;
}