.featured-container-old {
    display: flex;
    overflow-x: auto;
    color: white;
    justify-content: space-evenly;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;  
}

.featured-container {
    display: flex;
    flex-wrap: wrap;
    color: white;
    justify-content: space-evenly;
     
}

.title-featured-container {
    color: white;
    font-family: Inconsolata;
    
    margin-left: 20px;
    padding-top: 20px;

}

.featured-main-container {
   
}