.upcomingrows-container {
    max-width: 100%;
    min-width: 100%;
    
    cursor: pointer;
    
}

.upcomingrows-card {
    max-width: 90%;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.288);
    border-radius: 8px;
    min-width: 330px;
    
    margin-bottom: 20px;
}

.rows-followers-count-container {
   
   justify-content: space-evenly;
   display: flex;
    width: fit-content;
    
    margin-top: 15px;
    
    
}


.featured-projectimage-upcoming {
    width: 120px;
    border-radius: 10px;
    margin: 12px;
}

.image-container-upcomingrows {

}

.followers-stats-rows-card {
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: 6px;
    margin-left: 20px;
}

.featured-twitter-count {
    text-decoration: none;
    color: white;
    font-weight: 800;
    font-size: 11px;
    background-color: rgba(52,92,159);
    padding:6px;
    border-radius: 6px;
    margin-right: 2px;
    white-space: nowrap;
}

.sparking-row {
    width: 50px;
    color: rgb(88, 64, 224);
}

.row-second-tab {
    min-width: 130px;
    width: 130px;
}

.featured-date-container-row {
    margin-top: 8px;
   padding: 5px;
    width: 100%;
}

.row-thrid-tab {
    width: 200px;
    min-width: 200px;
}

.engagementrate {
    color: white;
    font-size: 15px;
    background-color: #273078;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;

    border-radius: 4px;
    width: fit-content;
    font-family: Inconsolata;

}

.featured-first-container-row {
    display: flex;
    overflow-x: scroll;
    width: fit-content;
    height: 100%;
    overflow-x: auto;
    
}

.blockchain-text-row {
    font-family: Inconsolata;
    font-weight: 600;
    font-size: 13px;
    background-color: #273078;
    color: white;
    width: fit-content;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 0px;
     
   
}