.next-drop-p {
    
    margin-left: 10px;
    font-size: 20px;

}

.next-drop-subtitle {
   
    font-size: 25px;
    margin-left: 10px;
}

.next-drop-title {
    font-size: 30px;
}

.dropscore-card-home-container {
    color: white;
    margin-top: 150px;
}

.next-drop {
    background: #26273A;  /* fallback for old browsers */

}

.upcoming-header-homepage {
    width: 90%;
    margin: auto;
    line-height: 200%;
    margin-top: 30px;
    font-weight: 600;
}

.next-drop-image {
    box-shadow: 5px 3px 80px  #e439ca60;
}