.featured-image {
    width: 100%;
    border-radius: 5px;
}

.postcards-container {
    background-color: rgba(0, 0, 0, 0.288);
    width: 350px;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.image-container {
    width: 90%;
    margin: auto;
    padding: 5px;
    margin-bottom: 0px;
}

.categories {
    background-color: #273078;
    font-family: Inconsolata;
    width: fit-content;
    margin-top: 15px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 13px;
    font-weight: 800;
    border-radius: 2px;
}

.date {
    font-family: Inconsolata;
    width: fit-content;
    border: white 1px solid;
    padding: 3px;
    border-radius: 5px;
}