.blogarticle-container {
    margin-top: 100px;
    color: white;
    width: 100%;
   margin-bottom: 100px;
  
    

}

.article {
    width: 80%;
    margin: auto;
    min-height: 70vh;
}

.postimage {
    width: 100%;
    border-radius: 20px;
}

.posttitle {
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 50px;
}


.aboutauthor {
    width: 80%;
    margin: auto;

   
    
}

.aboutauthor {
    background-color: rgba(0, 0, 0, 0.288);
    width: fit-content;
    align-items: center;
    margin: auto;
    display: flex;
    font-family: Inconsolata;
    border-radius: 50px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.verticaltext {
    writing-mode: vertical-lr;
    margin-top: 5px;
}

.author {
    display: flex;
    margin-left: 30px;
    

    
    width: fit-content;
   

    display: flex;
    font-family: Inconsolata;
   
    
}

.authoravatar {
    width: 30px;
    border-radius: 30px;
    margin-right: 5px;
}

.headersub {
    display: flex;
    flex-wrap: wrap;
}