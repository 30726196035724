* {
    margin: none;
    padding: none;
}
.form-container {
    width: 65%;
    align-items: center;
    justify-content: center;
    font-family: Inconsolata;
    color: white;
    min-height: 60vh;
    margin: auto;
    margin-top: 100px;

}

.mobile {
    width: 95%;
    align-items: center;
    justify-content: center;
    font-family: Inconsolata;
    color: white;
    min-height: 60vh;
    margin: auto;
    margin-top: 100px;
}



.label {
    padding: 0px;
    margin: 0px;
    
}

.button-subtmi {
    color: #6287F8;
    background-color: #6288f831;
    font-family: Inconsolata;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 98%;
    font-weight: 600;
    font-size: 19px;
    
    border: none;
    margin-top: 1px;
    
}

.label-container {
    
    
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px;
    text-align: center;
    width: 100%;
    
}

.button-container {
    align-items: center;
    text-align: center;
    width: 100%;
}


.form-container input, textarea{
    display: block;
    width: 98%;
    margin: 1%;
    padding: 2%;
    margin-top: -23px;
    margin-bottom: 0px;
    border:0;
    
    font-size: 15px;
}

.mobile input, textarea{
    display: block;
    width: 98%;
    margin: 1%;
    padding: 2%;
    margin-top: -23px;
    margin-bottom: 0px;
    border:0;
    
    font-size: 15px;
}

.input-form {
    background-color: rgba(0, 0, 0, 0.384);
    margin-top: 0px;
    color: white;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: -23px;
    margin-bottom: 0px;
    
}

.title-form-page {
    font-family: Inconsolata;
    color: white;
    text-align: center;
    margin-top: 70px;
    
}

.main-form-container {
    margin-top: 0px;
    margin-bottom: 0px;
}