.login-container {
    color: white;
    height: 80vh;
}

.form {
    margin: auto;
    width: 50%;
    padding-top: 20%;
    justify-content: center;
}



.form-login-mobile {
    margin: auto;
   
    width: 80%;
    padding-top: 20%;
    justify-content: center;
}

.form-login-mobile input {
    width: 100%;
    font-family: Inconsolata;
    height: 60px;
    border: none;
    margin-top: 5px;
    background: rgba(0, 0, 0, 0.384);
    
    padding: 10px;
    color: white;
}


.button {
    width: 100%;
    border: none;
    color: #6287F8!important;
    background:#6288f831!important;
    font-family: Inconsolata;
    font-size: 15px;
    font-weight: 800;
    
    height: 50px!important;
}

.form input {
    width: 100%;
    font-family: Inconsolata;
    height: 60px;
    border: none;
    margin-top: 5px;
    background: rgba(0, 0, 0, 0.384);
    
    padding: 10px;
    color: white;
}

.login-title {
    font-family: Inconsolata;
    
}