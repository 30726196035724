.footer-container {
    display: flex;
    font-family: Inconsolata;
    justify-content:space-around;
    background-color: rgba(0, 0, 0, 0.288);
    color: white;
    flex-wrap: wrap;
    padding: 40px;
    
}

.footer-column {
    padding-top: 30px;
    padding-bottom: 30px;
}


.footer-links {
    text-decoration: none;
    color: rgb(255, 255, 255); 
    font-weight: 700;
    padding: 3%;
}

.footer-social-links {
    display: flex;;
    justify-content: space-evenly
}

.footer-icon {
    color: white;
}

.footer-icon a{
    text-decoration: none;
}