.ethstatscard-container {
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
    font-size: 20px;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 8%);
    width: 250px;
    margin-bottom: 20px;
    color: rgb(255, 255, 255);
    background: #26273A;
      /* fallback for old browsers */
 


}



.volume-stats {
    text-decoration: none;
    background-color: #6288f81f;
    color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 700;
    width: fit-content;
    font-family: Inconsolata;
    font-size: 20px;
}