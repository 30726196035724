.tab-container {
    padding: 20px;
    border: solid 1px black;
    display: flex;
    justify-content: space-around;
    font-family: Inconsolata;
}

.tab-info {
    
}

.rank-image {
    width: 40px;
    border-radius: 50px;
}