.nftcarcontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    
    
}

.fetch-button {
    color: #ffffff;
    background-color: #3a3c42e1;
    border: none;
    border-radius: 8px;
    padding: 10px;
    font-family: Inconsolata;
    font-weight: 800;
    margin: 2px;
    font-size: 15px;
    white-space: nowrap;
    min-width: fit-content;
    

}



.fetch-button-highlights {
    color: #ffffff;
    background-color: #b32d65;
    border: none;
    
    padding: 10px;
    font-family: Inconsolata;
    font-weight: 800;
    margin: 2px;
    font-size: 15px;
    white-space: nowrap;
    min-width: fit-content;
}



.blockchain-text-modal-row {
    font-family: Inconsolata;
    font-weight: 600;
    font-size: 20px;
    background-color: #273078;
    color: white;
    width: fit-content;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    margin-top: 10px; 
    margin-right: 5px;
    white-space: nowrap;
    border: none;
}

.blockchain-selector-container {
    display: flex;
    margin-top: 20px;
    overflow-x: scroll;
    width: fit-content;
    max-width: 100%;
    height: 100%;
    display: flex;
    overflow-x: auto;
}

.fetch-button:hover {
    background: #6288f88a;
}

.fetch-button-date:hover {
    background: #65dc8980;
}

.fetch-button-date {
    color: #65dc88;
    background-color: #65dc8957;
    border: none;
    padding: 10px;
    font-family: Inconsolata;
    font-weight: 800;
}


.upcoming-container {
    background: #071E3D;  /* fallback for old browsers */
    height: 100%;
    color: white;
    margin-top: 50px;
    
}

.loading-container {
    margin-top: 50%;
    height: 80vh;
}

.lottieanimation {
    margin-top: 50%;
}

.filter-container {
    
    
}

.filter-button-container {
   
    width: fit-content;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
   
    
    
}


::-webkit-scrollbar {
    width: 10px;
  }


  ./* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }

  .searchbar-container {
      max-height: fit-content;
      min-width: 40%;
      margin-bottom: 15px;
  }


  .input-upcoming {
      width: 100%;
      background-color: rgba(255, 255, 255, 0);
      border: none;
      color: white;
      height: fit-content;
      padding: 10px;
      border-radius: 8px;
      
  }