.logo {
    font-family: Inconsolata;
    font-weight: 800;
    font-size: 25px;
    text-decoration: none;
    color: #ffffff;

}
.container-topbar {
    
    display: flex;
    background-color: #071E3D;
    padding-left: 10px;
    padding-top: 10px; /* fallback for old browsers */
    width: 100%;
    position: fixed;
    top: 0;
    
}

.menu-item {
    display: flex;
    margin: auto;
    vertical-align: baseline;
    
    vertical-align: baseline;
    
}




.menu-item-active {
    display: flex;
    margin: auto;
    vertical-align: baseline;
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(0.25turn, #2657eb, #E439CA, rgba(56,2,155,0));
    border-image-slice: 1;
    vertical-align: baseline;
    
}

.menu-text {
    margin-top: 7px;
    vertical-align: baseline;
    font-weight: 800;
    font-size: 17px;
    text-decoration: none;
    color: #ebe2e2;
    font-weight: 700;
    margin-left: 5px;
    margin-bottom: 10px;
    
}







.menu-text-container {
    border-bottom: white 1px solid;
    border-width:1px;
    margin-top: 10px;
    padding: 10px;
    margin-bottom: 10px;
  
}

.menu-text-container-last {
    
    margin-top: 10px;
    padding: 10px;
    margin-bottom: 10px;
}

.hamburger-menu {
    display: flex;
    
    width: 100%;
    justify-content: flex-end;
    margin-top: 0;
    margin-right: 10px;
}

.hamburger {
   margin-top: -5px;
   z-index: 12;
   position: absolute;
   justify-content: flex-end;
   
}


.hamburger-active {
    background: #1B1C31;
    width: 300px;
    height: fit-content;
    transition: all 0.1s ease-in-out;
    color: white;
    border: white 1px solid;
    font-family: Inconsolata;
    padding: 10%;
    z-index: 10;
    position: absolute;
    top: 0;
  right: 0;
}


.closebutton-hamburger-open {
    
    justify-content: flex-end;
    position: relative;
  float: right;
}

.dropscore-logo {
    color: #e2e7f8;
    background-color: #6288f856;
    padding: 1%;
    border-radius: 5px;
    
}

.logodropscore {
   width: 100%;

    
}



.logo-text-title {
    color: #ffffff;
    vertical-align: baseline;

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    
    
}


.topbar-rightpart {
    
    margin-top: -5px;
    width: 70%;
    display: flex;
    justify-content: flex-end;
    max-height: fit-content;
}

.logo {
    display: flex;
}

.topbar-left-container {
    display: flex;
    margin-left: 5px;

    align-items:flex-start;
    
    
    
    
}

.menu-item-logo {
    vertical-align: baseline;
    width: 300px;
    padding: 0px;
    margin-top: -135px;
    margin-bottom: -120px;
    

}

.menu-item-logo-text {
    margin-left: 12px;
    
}